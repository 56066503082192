<template>
  <main v-if="intergralData">
    <div class="banner">

      <router-link class="rule" :to="{ name: 'IntergralRule' }">
        <!-- <img src="https://www.bzwz.com/static/m/images/icon87.png" /> -->
        <img src="@/assets/img/intergral/icon87.png" />
        积分规则
      </router-link>
      <div class="integral" style="font-size: 12px">
        <span style="font-size:20px; ">{{ intergralData.jiFen }}</span> 分
      </div>
      <ul class="menu-nav">
        <li
            class="jifen"
            :class="currentMenuItem == item ? 'active' : ''"
            v-for="(item, index) in menu"
            :key="index"
            @click="selectMenu(item)"
        >
          <a href="javascript:;" rel="nofollow">{{ item }}</a>
        </li>
      </ul>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <!--积分明细-->
        <list url="/M/Server/GetJifenLastjf" :show="currentMenuItem == '积分明细'" class="swiper-slide swiper-no-swiping">
          <template v-slot:default="intergralData">
            <div class="swiper-content">
              <ul class="integral-ul jifen-ul">
                <li v-for="(item, index) in intergralData.data" :key="index">
                  <div class="date">
                    {{ dateFormat(item.createTime) }}
                  </div>
                  {{ item.logDetails }}

                  <div class="integral-count add-integral">
                    {{ item.operateScore }}
                  </div>
                </li>
              </ul>
            </div>

            <div
                class="date empty"
                v-if="intergralData.data && !intergralData.data.length"
            >
              <!-- <img src="https://www.bzwz.com/static/m/images/icon36.png" class="focus_img" /> -->
              <img src="@/assets/img/icon36.png" class="focus_img" />
              <div>暂无积分明细</div>
            </div>
          </template>
        </list>
        <!--兑换记录-->
        <list url="/M/Server/GetJifenLastdh" :show="currentMenuItem == '兑换记录'" class="swiper-slide swiper-no-swiping">
          <template v-slot:default="intergralData">
            <div class="swiper-content">
              <ul class="commodity-ul exchange-ul">
                <li
                    v-for="(item, index) in intergralData.data"
                    :key="index"
                >
                  <router-link
                      class="jifen_href"
                      :to="{
                      name: 'ExchangeDetail',
                      query: { num: item.orderNum },
                    }"
                  >
                    <div
                        v-if="item.giftProduct"
                        :style="{
                        backgroundImage:
                          item.giftProduct.pic.indexOf('https://') != -1
                            ? `url(${item.giftProduct.pic})`
                            : `url(https://www.bncc.com${item.giftProduct.pic})`,
                      }"
                        class="commodity-pic"
                    ></div>

                    <div class="commodity-title">
                      {{ item.title }}
                    </div>
                    <div class="exchange-date">
                      兑换时间： {{ dateFormat(item.createTime) }}
                      <span class="exchange-status" v-if="item.status == 0">兑换中</span>
                      <span class="exchange-status" v-if="item.status == 1">兑换完成</span>
                      <span class="exchange-status" v-if="item.status == 2">兑换失败</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>

            <div
                class="empty"
                v-if="
                intergralData.data && !intergralData.data.length
              "
            >
              <img
                  src="@/assets/img/common/icon36.png"
                  class="focus_img"
              />
              <div>暂无兑换记录</div>
            </div>
          </template>
        </list>
        <!-- 积分兑换 -->
        <div class="swiper-slide" v-show="currentMenuItem == menu[2]">
          <div class="swiper-content" :style="{ height: height }" style="padding-left: 4em;">

            <ul class="product-wapper">
              <li
                  class="product-item"
                  v-for="item in leftList"
                  @click="onClick(item.dictKey, item.sort)"
                  :key="item.id"
                  :class="active == item.sort ? 'active' : ''"
              >
                {{ item.dictValue }}
              </li>
            </ul>
            <ul class="second-class-wapper wares-ul" style="overflow: auto;height: 100%;padding: 0 5px;">
              <li v-for="(item, index) in children" :key="index">
                <a
                    href="javascript:;"
                    :style="{ backgroundImage: item.pic.indexOf('https://') != -1
                          ? `url(${item.pic})`
                          : `url(https://www.bncc.com${item.pic})` }"
                    class="wares-pic"
                >
                </a>
                <div class="wares-wapper">
<!--                  <div class="wares-title">-->
<!--                    {{ item.title }}-->
<!--                  </div>-->
                  <div class="wares-desc">
                    {{ item.description }}
                  </div>
                  <div class="exchange-date" v-if="item.type != 16">
                    <span style="color: #df0024">{{ item.needCredit }}</span>
                    积分
                    <div
                        class="btn"
                        @click="exchange(item.id, item.needCredit)"
                    >
                      查看
                    </div>
                  </div>
                  <div class="exchange-date" v-else style="height: 20px;">
                    <div
                            class="btn"
                            @click="exchange(item.id, item.needCredit)"
                    >
                      查看
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import list from "@/components/common/list.vue";

import { defineComponent, ref, onMounted, nextTick } from "vue";
import Loading from "@/components/UI/Loading";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import dateFormat from "@/utils/dateFormat";
import { useRoute, useRouter } from "vue-router";
import wechatLogin from "@/api/wechatLogin";
import qs from "qs";
import $ from "jquery";
import api from "@/api/axios";
export default defineComponent({
  name: "Intergral",
  components: {
    Loading,
    list
  },
  setup() {
    const intergralData = ref(null);
    const route = useRoute();
    const height = ref("0px");
    const active = ref(0);
    const children = ref([]);
    const leftList = ref([]);
    onMounted(() => {
      height.value =
          Number($(window).height()) -
          Number($("header").height()) -
          Number(135) -
          Number($("footer").height()) + "px";
    });
    function onClick(id: string, sort: number) {

      active.value = Number(sort);
      axios
          .get("/M/User/ExchangeProduct", {
            params: {
              type: id
            },
          })
          .then((res) => {
            if (res.data.success) {
              children.value = res.data.obj.plist;
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((err) => {
            console.log("err");
          });

    }

    type Menu = "积分明细" | "兑换记录" | "积分兑换";
    const menu: Menu[] = ["积分明细", "兑换记录", "积分兑换"];
    const type = route.query.type;
    const currentMenuItem = ref(menu[2]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    if (type) {
      currentMenuItem.value = menu[Number(type)];
    }
    wechatLogin(route.query.code as string, route.query.state as string).then(
        () => {
          axios
              .get("/M/User/ExchangeProduct")
              .then((res) => {
                if (res.data.success) {
                  intergralData.value = res.data.obj;
                  leftList.value = res.data.obj.listCategory;
                  children.value = res.data.obj.plist;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log("err");
              });
        }
    );

    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    const router = useRouter();
    function exchange(id: string, needCredits: string) {
      if (Number((intergralData.value as any).jiFen) < Number(needCredits)) {
        Modal({
          type: "none",
          title: "温馨提示",
          content: "您当前积分不够兑换该商品",
          confirmText: "查看积分规则",
          onConfirm: () => {
            router.push({
              name: "IntergralRule",
            });
          },
        });
      } else {
        router.push({
          name: "Exchange",
          query: {
            id,
          },
        });
      }
    }

    return {
      intergralData,
      dateFormat,
      menu,
      selectMenu,
      currentMenuItem,
      loadOption,
      exchange,
      height,
      leftList,
      onClick,
      active,
      children
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  // padding-bottom: 110px;
}

.banner {
  width: 100%;
  height: auto;
  position: relative;
  // background-image: linear-gradient(-90deg, #d64b4b 0%, #e86464 100%),
  //   linear-gradient(#eeeeee, #eeeeee);
  // background-blend-mode: normal, normal;
  background-color: #ee5e67;
  padding-top: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

.rule {
  position: absolute;
  right: 0;
  top: 20px;
  padding-right: 9px;
  color: #ffffff;
  font-size: 12px;
}

.rule img {
  width: 18px;
  margin-right: 2.5px;
  vertical-align: middle;
  transform: translateY(-1px);
}

.integral {
  color: #ffffff;
  font-size: 20px;
  margin-top: 7.5px;
}

/**

    菜单导航
  */
.menu-nav {
  overflow: hidden;
  height: 45px;
  line-height: 45px;
  margin-top: 20px;
}

.menu-nav li {
  float: left;
  width: 33.33%;
  text-align: center;
  position: relative;
}
.menu-nav li a {
  display: block;
  text-align: center;
  line-height: 45px;
  width: 100%;
  font-size: 15px;
  color: #fff;
}
.menu-nav .active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7.5px solid #fff;
  margin-left: -6px;
}
.integral-ul,
.commodity-ul,
.wares-ul {
  padding: 0 10px;
  box-sizing: border-box;
}

.integral-ul li {
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-right: 20px;
  color: #444444;
  font-size: 14px;
}

.integral-ul li .date {
  color: #999999;
  font-size: 13px;
  margin-bottom: 10px;
}

.integral-count {
  position: absolute;
  right: 0;
  text-align: right;

  font-size: 15px;

  top: 10px;

  /* line-height:  100%;

  transform: translateY(-50%); */
}

.add-integral {
  color: #52ba65;
}

.sub-integral {
  color: #cc3333;

}

.commodity-ul li {
  border-bottom: 1px solid #eee;
  padding: 17.5px 0;
  padding-left: 72px;
  overflow: hidden;
  position: relative;
}
.commodity-ul .commodity-pic {
  width: 62px;
  height: 62px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  left: 0;
  top: 17.5px;
}

.commodity-ul .commodity-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #444444;
  font-size: 15px;
}

.commodity-ul .exchange-date {
  margin-top: 15px;
  color: #999999;
  font-size: 14px;
}

.exchange-status {
  float: right;
  color: #df0024;
}

.wares-ul li {
  border-bottom: 1px solid #eee;
  padding-top: 17.5px;
  padding-left: 105px;
  overflow: hidden;
  position: relative;
  height: 127.5px;
}

.wares-ul .wares-pic {
  width: 99px;
  height: 99px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  left: 0;
  top: 17.5px;
}

.wares-wapper {
  height: 99px;
}

.wares-ul .wares-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #444444;
  font-size: 15px;
}

.wares-ul .wares-desc {
  color: #999;
  font-size: 14px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
}

.wares-ul .exchange-date {
  /* margin: 15px 0; */
  color: #999999;
  position: relative;
  font-size: 14px;
}

.wares-ul .btn {
  position: absolute;
  right: 11px;
  top: 240%;
  text-align: center;
  width: 72.5px;
  height: 27.5px;
  /* border-radius: 16.5px; */
  /* border: solid 1px #d64b4b; */
  background-color: #df0024;
  line-height: 27.5px;
  color: #fff;
  font-size: 14px;
  transform: translateY(-50%);
}

.exchange_more {
  width: 100%;
  height: 75px;
  text-align: center;
  line-height: 50px;
  font-size: 15px;
  color: #666;
}

.empty {
  padding-top: 4.26667rem;
  text-align: center;
  padding-bottom: 1.33333rem;
  color: #9f9f9f;
  font-size: 0.37333rem;
  img {
    width: 3.52rem;
    height: 2.77333rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.4rem;
  }
}

.product-wapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 7em;
  background-color: #f5f5f5;
  height: 100%;
  font-size: 14px;
  overflow-y: auto;
  // padding-bottom: 40px;
  box-sizing: border-box;
  .product-item {
    width: 100%;
    padding-left: 7px;
    box-sizing: border-box;
    line-height: 50px;
    overflow: hidden;
    color: #656565;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .active {
    color: #df0024;
    border-left: 3px solid;
    background-color: #fff;
  }
}
</style>